@keyframes borderBlink {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
}

.border-blink {
  border: 2px solid transparent;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  animation: borderBlink 1.5s infinite;
}
