.balon {
  position: absolute;
  width: 10%;
  animation: moveAndResize 5s infinite;
}

@keyframes moveAndResize {
  0% {
    top: 10%;
    left: 80%;
    transform: scale(1.5);
  }
  50% {
    top: 80%;
    left: 15%;
    transform: scale(0.5);
  }
  75% {
    top: 50%;
    left: 15%;
    transform: scale(0.5);
  }
  100% {
    top: 10%;
    left: 80%;
    transform: scale(1.5);
  }
}
