.cohete-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cohete {
  position: absolute;
  width: 15%;
  animation: moveRocket 15s infinite linear;
}

/* Movimiento del cohete */
@keyframes moveRocket {
  0% {
    bottom: 0;
    left: -15%;
    transform: translateX(0) translateY(0) rotate(-10deg);
  }
  30% {
    bottom: 65%;
    left: 30%;
    transform: translateX(0) translateY(-30%) rotate(50deg);
  }
  60% {
    bottom: 60%;
    left: 70%;
    transform: translateX(0) translateY(-30%) rotate(90deg);
  }
  100% {
    bottom: 0;
    left: 100%;
    transform: translateX(0) translateY(0) rotate(120deg);
  }
}
