.modal-perfil {
  transform: translateY(220px) translateX(-208px); /* Ajuste para posicionar el modal correctamente */
  width: 450px;
  height: 350px;
}

.modal-arrow-perfil {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.modal-line-perfil {
  border: none;
  border-top: 2px solid black;
  margin: 16px 0;
}
