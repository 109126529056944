.petalo {
  position: absolute;
  animation:
    fall 3s linear forwards,
    sway 3s ease-in-out infinite,
    resize 7s ease-in-out;
}

/* Movimiento de los petalos */
@keyframes fall {
  to {
    top: 100%;
  }
}

@keyframes sway {
  0%,
  100% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
}

@keyframes resize {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
