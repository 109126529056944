.modal {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 350px;
  height: 255px;
  transform: translateY(175px) translateX(125px);
  /* Ajuste para posicionar el modal correctamente */
}

.modal-wrapper {
  position: fixed;
  z-index: 50;
}

.modal-img {
  height: 2rem;
  width: auto;
  margin: auto;
}

.modal-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.modal-title {
  color: black;
  font-size: 1.25rem;
  padding-top: 1.25rem;
  margin-bottom: 1rem;
}

.modal-text {
  color: black;
  font-weight: 500;
}

.modal-content {
  position: relative;
}