@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.module {
  animation: fadein 2s ease-in-out infinite,
             glow 2s ease-in-out infinite alternate;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Animaciones para las vistas previas */
@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  25% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 25px #fff
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff
  }
}

.App {
  text-align: center;
}


.App-logo {
  height: 7vmin;
  pointer-events: none;
}

/* Animación para rotación del logo */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animación para la bola de heno del viejo oeste */
@keyframes tumbleweed {
  0% {
    transform: translateX(2000%) rotate(0deg) translateY(-50px); /* Ajusta el valor aquí */
  }
  10% {
    transform: translateX(1780%) rotate(0deg) translateY(-80px); /* Ajusta el valor aquí */
  }
  20% {
    transform: translateX(1560%) rotate(-120deg) translateY(-50px); /* Ajusta el valor aquí */
  }
  30% {
    transform: translateX(1340%) rotate(-400deg) translateY(-80px); /* Ajusta el valor aquí */
  }
  40% {
    transform: translateX(1120%) rotate(-540deg) translateY(-50px); /* Ajusta el valor aquí */
  }
  50% {
    transform: translateX(900%) rotate(-800deg) translateY(-80px); /* Ajusta el valor aquí */
  }
  60% {
    transform: translateX(680%) rotate(-900deg) translateY(-50px); /* Ajusta el valor aquí */
  }
  70% {
    transform: translateX(460%) rotate(-1080deg) translateY(-80px); /* Ajusta el valor aquí */
  }
  80% {
    transform: translateX(240%) rotate(-1260deg) translateY(-50px); /* Ajusta el valor aquí */
  }
  90% {
    transform: translateX(20%) rotate(-1440deg) translateY(-80px); /* Ajusta el valor aquí */
  }
  100% {
    transform: translateX(-200%) rotate(-1620deg) translateY(-50px); /* Ajusta el valor aquí */
  }
}


.tumbleweed {
  position: absolute;
  bottom: 20%;
  width: 120px;
  height: 120px;
  animation: tumbleweed 15s linear infinite;
  animation-delay: calc(0.5s * var(--random));
  z-index: 1000;
}

/* Animación para los carritos en Grand */
@keyframes moveLeftToRight {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(2000%);
  }
}

.kart1 {
  position: absolute;
  bottom: 18%;
  width: 240px;
  height: 120px;
  animation: moveLeftToRight 1s linear infinite;
}

.kart2 {
  position: absolute;
  bottom: 30%;
  width: 240px;
  height: 120px;
  animation: moveLeftToRight 8s linear infinite;
}