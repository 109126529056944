.meteorito {
    position: absolute;
    width: 10%;
    transition: top 0.016s linear, left 0.016s linear;
}

.cohete {
    position: absolute;
    width: 15%;
    transition: top 0.016s linear, left 0.016s linear;
}